<template>
  <div style="background-color: #faf9f9">
    <Myhead :nav="6"></Myhead>
    <div class="header">
      <div class="header-content">
        <div class="header-title">{{ Language.aboutUs.company }}</div>
        <div class="header-text">{{ Language.aboutUs.message1 }}</div>
      </div>
    </div>
    <div class="router">
      <div class="routerbox">
        <router-link class="routerLink" to="/">{{ Language.aboutUs.home_page }}</router-link>
        >
        <span class="routerLink" @click="reload">{{ Language.aboutUs.aboutus }}</span> >
        <span @click="reload"
              style="color: var(--theme-color1);cursor: pointer">{{ Language.aboutUs.brief_introduction }}</span>
      </div>
    </div>
    <div class="content1" id="about1">
      <div class="textbox">
        <div class="content1-title">{{ Language.aboutUs.aboutus }}</div>
        <div class="content1-text">
          {{ Language.aboutUs.message2 }}
        </div>
      </div>
      <div class="content1-img">
        <div class="imgbox">
          <a-carousel :after-change="onChange" effect="fade" autoplay>
            <div>
              <img src="../assets/about/about1.jpg" alt/>
            </div>
            <div>
              <img src="../assets/about/about2.jpg" alt/>
            </div>
            <div>
              <img src="../assets/about/about3.jpg" alt/>
            </div>
            <div>
              <img src="../assets/about/about4.jpg" alt/>
            </div>
            <div>
              <img src="../assets/about/about5.jpg" alt/>
            </div>
            <div>
              <img src="../assets/about/about6.jpg" alt/>
            </div>
            <div>
              <img src="../assets/about/about7.jpg" alt/>
            </div>
          </a-carousel>
        </div>
        <div class="box"></div>
      </div>
    </div>
    <div style="background-color: var(--theme-color1);">
      <div class="content2">
        <div class="textbox">
          <div class="content2-title">{{ Language.aboutUs.Ability_and_Qualification }}</div>
          <div class="content2-text">
            <ul>
              <li>{{ Language.aboutUs.message3 }}</li>
              <li>{{ Language.aboutUs.message4 }}</li>
              <li>{{ Language.aboutUs.message5 }}</li>
            </ul>
          </div>
        </div>
        <div class="content2-img">
          <vue-seamless-scroll style="width: 100%;overflow: hidden" :data="imgs" :class-option="ScrollOption">
            <div style="display: flex">
              <img v-for="item in imgs" :src="item.img" :alt="item.alt"/>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
    <div class="content3" id="about2">
      <div class="content3-title">{{ Language.aboutUs.Cooperation }}</div>
      <div class="content3-text">
        {{ Language.aboutUs.message6 }}
      </div>
      <div class="content3-text">
        {{ Language.aboutUs.message7 }}
      </div>
      <div class="content3-img">
        <img class="imgStyle" src="../assets/img/aboutUs/cooperator.png" alt/>
      </div>
    </div>
    <div style="background-color: #ffffff;" id="about3">
      <div class="content4">
        <div class="content4-title">{{ Language.aboutUs.Clients }}</div>
        <div class="flex">
          <div class="text">
            <div class="content4-text">
              {{ Language.aboutUs.message8 }}
            </div>
            <div class="content4-text" style="margin-top: 36px;">
              {{ Language.aboutUs.message9 }}
            </div>
          </div>
          <div class="content4-img">
            <img class="imgStyle" src="../assets/img/aboutUs/customer.png" alt/>
          </div>
        </div>

      </div>
    </div>
    <Myfoot @getLocalID="getLocalID"></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";

export default {
  inject: ["reload", 'L'],

  components: {
    Myhead,
    Myfoot,
  },
  data() {
    return {
      imgs: [
        {
          img: require("../assets/about/zs1.jpg"),
          alt: '证书'
        },
        {
          img: require("../assets/about/zs2.jpg"),
          alt: '证书'
        },
        {
          img: require("../assets/about/zs3.jpg"),
          alt: '证书'
        },
        {
          img: require("../assets/about/zs4.jpg"),
          alt: '证书'
        },
        {
          img: require("../assets/about/zs5.jpg"),
          alt: '证书'
        },
      ],
    }
  },
  computed: {
    ScrollOption() {
      return {
        step: 0.1, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    Language() {
      return this.L()
    }
  },
  watch: {},
  created() {
    this.$nextTick(() => {
      this.getLocalID()
    })
  },
  methods: {
    getLocalID() {
      let select = localStorage.getItem("LocalID")
      let elm = document.getElementById(select)
      if (select) {
        localStorage.removeItem("LocalID")
        elm.scrollIntoView(true)
      }
    },
    onChange(e) {
      // console.log(e)
    },
  }
}
</script>
<style lang="scss" scoped>

.header {
  margin-top: -70.5px;
  height: 360px;
  background-image: url("../assets/img/aboutUs/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #ffffff;

  .header-content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 150px;

    .header-title {
      font-size: 42px;
      line-height: 58px;
      margin-bottom: 16px;
    }

    .header-text {
      max-width: 558px;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.router {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  font-size: 14px;

  .routerbox {
    width: 1200px;
    margin: 0px auto;
  }

}

.content1 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .content1-title {
    color: var(--title-color);
    font-size: 42px;
    margin-bottom: 24px;
  }

  .content1-text {
    width: 510px;
    color: var(--text-color);
    line-height: 28px;
    text-align: justify;
  }

  .imgbox {
    width: 585px;
    height: 400px;
    position: relative;
    z-index: 100;
    margin-top: 30px;
    border-radius: 10px;
    overflow: hidden
  }

  .content1-img {
    width: 615px;
    height: 430px;
    position: relative;

    img {
      width: 585px;
      height: 400px;
    }

    .box {
      width: 380px;
      height: 380px;
      background-color: var(--theme-color1);
      border-radius: 24px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }

}

.content2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  .textbox{
    width: calc(100% - 725px);
  }
  .content2-title {
    font-size: 42px;
    margin-bottom: 24px;
  }

  .content2-text {
    line-height: 28px;

    ul {
      //padding-inline-start: 20px;
    }
  }

  .content2-img {
    width: 705px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      flex-shrink: 0;
      width: 215px;
      height: 325px;
      margin-right: 20px;
    }
  }
}

.content3 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 0;
  text-align: center;

  .content3-title {
    color: var(--title-color);
    font-size: 42px;
    margin-bottom: 24px;
  }

  .content3-text {
    color: var(--text-color);
    line-height: 28px;
  }

  .content3-img {
    max-width: 1200px;
    //height: 415px;
    margin-top: 40px;
  }
}

.content4 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 0;

  .content4-title {
    color: var(--title-color);
    font-size: 42px;
    margin-bottom: 24px;
  }

  .content4-text {
    width: 385px;
    color: var(--text-color);
    line-height: 28px;
    text-align: justify;
  }

  .content4-img {
    width: 690px;
    height: 290px;
  }
}


@media (min-width: 0px) and (max-width: 1200px) {
  .header {
    .header-content {
      padding: 130px 20px 0;
    }
  }
  .router {
    .routerbox {
      padding: 0px 20px;
    }
  }

  .content1 {
    width: 100%;
    flex-wrap: wrap;
    .textbox{
      width: 100%;
      padding: 0px 20px;
      .content1-text{
        width: 100%;
      }
    }
    .imgbox{
      width: 100%;
      height: auto;
    }
    .content1-img {
      width: 100%;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
    }
  }

  .content2{
    flex-wrap: wrap;
    .textbox{
      width: 100%;
      padding: 0px 20px;
    }
    .content2-img{
      width: 100%;
      margin-top: 40px;
    }
  }
  .content4{
    .content4-title{
      padding:0px 20px;
    }
    .flex{
      flex-wrap: wrap;
      .text{
        width: 100%;
        padding: 0px 20px;
        .content4-text{
          width: 100%;
        }
      }

      .content4-img{
        width: 100%;
        height: auto;
        margin-top: 20px;
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 1920px) {
  .header{
    background-size: auto 100%;
  }
}
</style>
